import React, { useState } from "react"
import styled from "styled-components"

import TextRainbow from "../../../components/TextRainbow"

const SubTitle = styled.h4`
    display: block;
    margin: 0 0 1.5rem 0;
    color: #999;
`
const Button = styled.div`
    border-radius: 5px;
    padding: 0.5em;
    margin: 2em auto;
    background: #FFFFCF;
    color: #212121;
    width: fit-content;

    &:hover {
        cursor: pointer;
    }
`
const TweetContainer = styled.div`
    width: fit-content;
    margin: auto auto;
    padding: 1em 0;
`
const TweetBox = styled.textarea`
    display: block;
    border-radius: 5px;
    padding: 0.25em;
    resize: none;
`
const Error = styled.div`
    padding: 0.25em;
    width: 100%;
    background: #FF6961;
    color: #FFF;
    font-weight: bolder;
    margin: 1em;
`

const ShowTweet = (props: any) => {
    const { tweetId, tweetText } = props;

    return (<>
        <SubTitle>
            You have represented #TimeKeepers this hour!
        </SubTitle>

        <blockquote className="twitter-tweet">
            <p lang="en" dir="ltr">
                {tweetText}
            </p>&mdash; EnsTimeKeepersBot (@EnsTimeKeepers) <a href={`https://twitter.com/EnsTimeKeepers/status/${tweetId}?ref_src=twsrc%5Etfw`} target="_blank" rel="nofollow noreferer">View on Twitter</a></blockquote> 
        <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
        
    </>)
}

export default ShowTweet